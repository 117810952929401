
export default {
  name: 'Story',
  props: ["url"],
  data() {
    return {
      profiles: [],
    };
  },
  created() {
    let stories = this.$store.state.alumni.profiles;
    this._data.profiles = stories.filter((img) => {
      return img.isStory;
    });
  },
}
