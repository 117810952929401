
import HomeCarousel from "@/components/HomeCarousel.vue";
import Announcement from "@/components/Announcement.vue";
import Story from "@/components/Story.vue";
import Features from "@/components/Features.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "Home",
  data() {
    return {
      sdp: {
        courseName: "",
        courseStartDateText: "",
        coverImage: "",
        isSoldOut: false,
        nextBatchDate: ""
      },
      wdj: {
        courseName: "",
        courseStartDateText: "",
        isSoldOut: false,
        nextBatchDate: ""
      },
      ffw: {
        courseName: "",
        courseStartDateText: "",
        isSoldOut: false,
        nextBatchDate: ""
      },
    };
  },
  components: {
    HomeCarousel,
    Announcement,
    Story,
    Features,
    Contact
  },
  created() {
    this.wdj = this.$store.state.wdj;
    this.wdj.coverImage = require("../assets/code.jpg");
    this.wdj.url = "/web-development-js";

    this.sdp = this.$store.state.sdp;
    this.sdp.coverImage = require("../assets/coding.jpg");
    this.sdp.url = "/software-design";

    this.ffw = this.$store.state.ffw;
    this.ffw.coverImage = require("../assets/frontend1.jpeg");
    this.ffw.url = "/frontend-frameworks";
  }
};
