
import SoldOut from "@/components/SoldOut.vue";

export default {
  name: 'Announcement',
  props: {
    course: Object,
    dir: String
  },
  components: {
    SoldOut
  }
}
