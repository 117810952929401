
export default {
  name: 'HomeCarousel',
  data: () => {
    return {
      items: [
        {
          src: require('../assets/carousel5.png'),
          imgIndex: 'first',
          state: 'active',
          heading: 'Accelerate Your Career',
          subheading: 'Give your career a boost, learn from industry experts.',
          webinarButton: 'Free Webinar',
          learnButton: 'Learn More',
        },
        {
          src: require('../assets/carousel2.png'),
          imgIndex: 'second',
          state: '',
          heading: 'Think First, Code Later',
          subheading: 'Experts think differently and you should too.',
          webinarButton: 'Free Webinar',
          learnButton: 'Learn More',
        },
        {
          src: require('../assets/carousel3.png'),
          imgIndex: 'third',
          state: '',
          heading: 'Lead and Inspire Others',
          subheading: 'Lead a team, build great products.',
          webinarButton: 'Free Webinar',
          learnButton: 'Learn More',
        },
      ],
    };
  },
}
